<template>
    <div class="mian-header">
        <div class="right">
            <div v-for="(item,index) in navList" :key="item.id" @click="navItem(index,item.id)">
                <div class="nav-item" :class="activeIndex==index?'active':''">
                    <i class="iconfont" v-html="item.iconfont"></i>
                    <span>{{ item.title }}</span>
                </div>
            </div>
            <el-button v-if="!token" class="white-btn" plain round @click="login">登录</el-button>
            <el-dropdown v-if="token" @command="handleCommand">
				<span class="el-dropdown-link">
					{{ userName }}<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="2">修改密码</el-dropdown-item> -->
                    <el-dropdown-item command="1">退出</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 登录弹窗 -->
        <el-dialog :visible.sync="dialogVisible" width="400px" :append-to-body="true" custom-class="login-dialog"
                   top="30vh">
            <div class="right" v-if="dialogVisible">
                <div class="title">登录</div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                    <el-form-item prop="user">
                        <el-input prefix-icon="el-icon-user" placeholder="请输入账号"
                                  v-model="ruleForm.user"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"
                                  v-model="ruleForm.password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="main-btn" type="primary" @click="submitForm('ruleForm')">登录</el-button>
                    </el-form-item>
                </el-form>
<!--                <div class="link" @click="registerLink">没有账号？立即注册！</div>-->
            </div>
        </el-dialog>
        <!-- 注册弹窗 -->
        <el-dialog :visible.sync="registerVisible" width="400px" :append-to-body="true" custom-class="login-dialog"
                   top="25vh">
            <div class="right" v-if="registerVisible">
                <div class="title">注册</div>
                <el-form :model="registerForm" :rules="registerRules" ref="registerForm">
                    <el-form-item prop="phone" v-if="stepOneShow">
                        <el-input prefix-icon="el-icon-mobile" placeholder="请输入手机号" v-model="registerForm.phone">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="code" v-if="stepOneShow">
                        <el-input prefix-icon="el-icon-document-checked" placeholder="请输入验证码"
                                  v-model="registerForm.code"></el-input>
                        <el-button class="code-btn" type="primary" size="mini" @click="sendCode"
                                   :disabled="codeDisabled">{{ send }}
                        </el-button>
                    </el-form-item>
                    <el-form-item prop="password" v-if="stepOneShow">
                        <el-input prefix-icon="el-icon-lock" placeholder="请输入登录密码" type="password"
                                  v-model="registerForm.password"></el-input>
                    </el-form-item>
                    <el-form-item prop="company" v-if="!stepOneShow">
                        <i class="iconfont">&#xeace;</i>
                        <el-select v-model="registerForm.company" placeholder="请选择保险公司名称">
                            <el-option label="保险公司一" value="1"></el-option>
                            <el-option label="保险公司二" value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="selectedOptions" v-if="!stepOneShow">
                        <i class="iconfont">&#xe614;</i>
                        <el-cascader size="large" :options="options" placeholder="请选择省市区"
                                     v-model="registerForm.selectedOptions" @change="addressChange">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item prop="name" v-if="!stepOneShow">
                        <el-input prefix-icon="el-icon-s-custom" placeholder="请输入姓名" v-model="registerForm.name">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="contactPhone" v-if="!stepOneShow">
                        <el-input prefix-icon="el-icon-mobile" placeholder="请输入联系电话"
                                  v-model="registerForm.contactPhone">
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="jobNo" v-if="!stepOneShow">
                        <el-input prefix-icon="el-icon-postcard" placeholder="请输入工号" v-model="registerForm.jobNo">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="main-btn" type="primary" @click="registerSubmit('registerForm')">
                            {{ registerBtnText }}
                        </el-button>
                    </el-form-item>
                </el-form>
                <div class="link" @click="loginLink">登录</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import http from "../../../http.js";
import {
    regionData
} from 'element-china-area-data'

export default {
    components: {},
    data() {
        return {
            stepOneShow: true,
            registerBtnText: '下一步',
            codeDisabled: false,
            send: '发送验证码',
            activeIndex: 0,
            navList: [{
                id: 1,
                iconfont: '&#xe612;',
                title: "首页"
            }],
            token: "",
            userName: "",
            dialogVisible: false,
            ruleForm: {
                user: "",
                password: "",
            },
            rules: {
                user: [{
                    required: true,
                    message: "请输入正确的账号",
                    trigger: "blur",
                }],
                password: [{
                    required: true,
                    message: "请输入正确的密码",
                    trigger: "blur",
                }],
            },
            registerVisible: false,
            options: regionData, //省市区数据
            registerForm: {
                phone: "",
                code: "",
                password: "",
                company: '',
                contactPhone: '',
                name: '',
                jobNo: '',
                selectedOptions: [],
            },
            registerRules: {
                phone: [{
                    required: true,
                    message: "请输入手机号",
                    trigger: "blur"
                },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号",
                        trigger: "blur",
                    }
                ],
                code: [{
                    required: true,
                    min: 4,
                    max: 4,
                    message: "请输入4位验证码",
                    trigger: "blur",
                }],
                password: [{
                    required: true,
                    message: "请输入登录密码",
                    trigger: "blur"
                },
                    {
                        min: 8,
                        max: 16,
                        pattern: /^(?![a-zA-Z]+$)(?!\d+$)(?![^\da-zA-Z\s]+$).{8,16}$/,
                        message: "由字母、数字、特殊字符，任意2种组成，8-16位",
                        trigger: "blur",
                    },
                ],
                company: [{
                    required: true,
                    message: '请选择保险公司',
                    trigger: 'change'
                }],
                selectedOptions: [{
                    required: true,
                    message: '请选择省市区',
                    trigger: 'change'
                }],
                name: [{
                    required: true,
                    message: "请输入姓名",
                    trigger: "blur",
                }],
                contactPhone: [{
                    required: true,
                    message: "请输入联系电话",
                    trigger: "blur"
                },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: "请输入正确的手机号",
                        trigger: "blur",
                    }
                ],
                jobNo: [{
                    required: true,
                    message: "请输入工号",
                    trigger: "blur",
                }],
            },
        };
    },
    created() {
        this.init();
    },
    methods: {
        addressChange(val) {
            console.log(val)
        },
        sendCode() {
            let _this = this
            _this.codeDisabled = true;
            var time = 60; //时间为10s，可以按情况更改
            var timer = setInterval(fun, 1000); //设置定时器
            function fun() {
                time--;
                if (time >= 0) {
                    _this.send = time + "s后重新发送";
                } else if (time < 0) {
                    _this.send = "重新发送验证码";
                    _this.codeDisabled = false; //倒计时结束能够重新点击发送的按钮
                    clearInterval(timer); //清除定时器
                    time = 60; //设置循环重新开始条件
                }
            }
        },
        registerLink() {
            this.registerVisible = true
            this.dialogVisible = false
        },
        loginLink() {
            this.registerVisible = false
            this.dialogVisible = true
        },
        navItem(index, id) {
            this.activeIndex = index
            if (id == 1) {
                this.$router.push('/insure')
            }
        },
        getInfo() {
            http.fetchGet("/api/admin/info").then((res) => {
                console.log('getInfo')
                if (res.data.code == 0) {
                    let powers = res.data.data.powers.toString();
                    localStorage.setItem("powers", powers);
                    this.$emit("onLogin")
                }
            });
        },
        registerSubmit(formName) {
            if (this.stepOneShow) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.stepOneShow = false
                        this.registerBtnText = "立即注册"
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            } else {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.registerVisible = false
                        this.$message({
                            message: '注册成功！',
                            type: 'success'
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }

        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    http.fetchPost("/api/admin/login", {
                        account: this.ruleForm.user,
                        password: this.ruleForm.password,
                    }).then((res) => {
                        if (res.data.code == 0) {
                            localStorage.setItem("token", res.data.data.token);
                            localStorage.setItem("name", res.data.data.name);
                            this.token = res.data.data.token;
                            this.userName = res.data.data.name;
                            this.getInfo();
                            this.$message.success("登录成功");
                            this.dialogVisible = false
                        } else {
                            if (this.ruleForm.user == 'test') {
                                this.$message.error('测试账号已过期')
                            } else {
                                this.$message.error(res.data.desc);
                            }
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        login() {
            this.dialogVisible = true
        },
        init() {
            let token = localStorage.getItem("token");
            let userName = localStorage.getItem("name");
            if (token) {
                this.token = token;
            } else {
                this.token = "";
                this.login()
            }
            if (userName) {
                this.userName = userName;
            } else {
                this.userName = "";
            }
        },
        handleCommand(command) {
            if (command == 1) {
                this.$confirm("确定要退出吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.token = "";
                        this.userName = "";
                        localStorage.clear();
                        this.dialogVisible = true
                    })
                    .catch(() => {
                    });
            } else if (command == 2) {
                this.$router.push("/resetPassword?from=/query")
            }
        },
    },
};
</script>

<style lang="scss">
//scss
</style>
