<template>
	<div class="insure-home">
		<QueryHeader @onLogin="handleLogin"></QueryHeader>
		<router-view></router-view>
	</div>
</template>

<script>
	import QueryHeader from "./components/header.vue"
	export default {
		components: {
			QueryHeader
		},
		data() {
			return {

			}
		},
		created() {

		},
		methods: {
			handleLogin() {}
		}
	}
</script>

<style lang="scss">
	@import '~@/assets/css/insure.scss';

	body {
		min-width: 1200px;
	}
</style>
